import React from 'react';
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';

import ContentContainer from '../components/Layouts/ContentContainer';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

import Content, { HTMLContent } from '../components/Content';

import classes from './product-page.module.scss';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

import { useSpring, animated } from 'react-spring';

export const ProductPageTemplate = ({
  name,
  brand,
  image,
  excerpt,
  categories,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;

  const imageSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const productNameSpring = useSpring({
    from: { opacity: 0, transform: 'translateX(-100px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    delay: 250,
  });

  const brandSpring = useSpring({
    from: { opacity: 0, transform: 'translateX(-100px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    delay: 300,
  });

  const excerptSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(100px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 350,
  });

  const contentSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(100px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 200,
  });

  return (
    <div className={classes.productPageContainer}>
      <Breadcrumb
        paths={[
          { text: 'Products & Services', link: '/categories' },
          { text: categories },
          { text: name },
        ]}
      />
      <div className={`columns ${classes.productHeader}`}>
        <div className="container">
          <div className="columns">
            <animated.div
              native
              style={imageSpring}
              className={`column is-4 ${classes.featuredImage}`}
            >
              <PreviewCompatibleImage
                imageInfo={{
                  alt: 'product',
                  image: image,
                }}
              />
            </animated.div>
            <div className="column is-8">
              <animated.h1
                native
                style={productNameSpring}
                className="title is-size-2 is-bold-light"
              >
                {name}
              </animated.h1>
              {brand && (
                <animated.h3
                  native
                  style={brandSpring}
                  className="subtitle is-size-4"
                >
                  Brand: {brand}
                </animated.h3>
              )}
              <animated.div natiev style={excerptSpring} className="excerpt">
                {excerpt}
              </animated.div>
            </div>
          </div>
        </div>
      </div>
      {content && (
        <ContentContainer>
          <animated.div className="columns" native style={contentSpring}>
            <div className="column is-10 is-offset-1">
              <PostContent content={content} />
            </div>
          </animated.div>
        </ContentContainer>
      )}
    </div>
  );
};

const ProductPage = ({ data }) => {
  const { frontmatter: product, html } = data.markdownRemark;
  return (
    <Layout>
      <ProductPageTemplate
        name={product.name}
        image={product.featuredimage}
        content={html}
        contentComponent={HTMLContent}
        brand={product.brand}
        excerpt={product.excerpt}
        categories={product.categories}
      ></ProductPageTemplate>
    </Layout>
  );
};

export default ProductPage;

export const pageQuery = graphql`
  query ProductQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        name
        tags
        brand
        excerpt
        categories
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;
