import React from 'react';

const ContentContainer = (props) => {
  return (
    <div className="container">
      <div style={{ padding: ' 1.5rem 1.5rem 3rem 1.5rem' }}>
        {props.children}
      </div>
    </div>
  );
};

export default ContentContainer;
